import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'gatsby';
import * as styles from '../../styles';
import {
  Box,
  Clients,
  ContactForm,
  Grid,
  Header,
  Heading,
  Hero,
  Layout,
  ProductRegisters,
  Projects,
  PurchaseParts,
  Reveals,
  Section,
  Services,
} from '../../components';
import { Footer } from '../../components';

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;  /* Centers the content horizontally */
    align-items: center;      /* Centers the content vertically */
    padding: 100px 100px;           /* Sets padding around the image */
`;

// Styled component for the image
const StyledImage = styled.img`
    max-width: 100%;         /* Ensures the image is responsive */
    height: auto;            /* Maintains aspect ratio */
`;

const StyledParagraph = styled.div`
    padding: 20px 20px;
    max-width: 1300px
    margin: 0 auto;
    a {
     color: white;
    }
`



const ProjectPage = () => (
    <Layout>
        <Header></Header>
        <Section margin="medium" textAlign="center">
            <Heading type="h2">UniSA</Heading>
            <Heading type="h4">Health Innovation Building</Heading>
        </Section>
        <Section margin="small" textAlign="center">
            <Reveals.FadeBottom>
                <StyledParagraph>In 2018, Air Con Serve were part of the team that delivered the University of South Australia’s Cancer Research Institute building, an integral part of Adelaide’s $3.8 billion BioMed City on North Terrace. It accommodates UniSA’s School of Pharmacy and Medical Sciences and the globally-recognised Centre for Cancer Biology - an alliance between UniSA and SA Health.</StyledParagraph>
                <StyledParagraph>This energy-intensive laboratory facility was designed and constructed to target a certified LEED Gold rating from the US Green Building Council, one of the highest global standards for sustainability. Given the various functions performed within the Cancer Research Institute building – from medical research laboratories to office and administration spaces, teaching spaces and public amenity – the building services design is naturally “services heavy” with a number of different systems and solutions utilised.</StyledParagraph>
            </Reveals.FadeBottom>
        </Section>
        <Section backgroundColor={styles.palette.darkGray} margin="medium">
            <ImageContainer>
                <StyledImage src="/img/projects/project-01.jpg" alt="HIB Building" />
            </ImageContainer>
        </Section>
        <Section margin="small" textAlign="center">
            <Reveals.FadeBottom>
                <StyledParagraph>One of the standout features of the building is its advanced HVAC system which comprises a central, water-cooled chilled water system comprising of high efficiency centrifugal chillers with associated cooling towers for heat rejection. Heating hot water is provided by high-efficiency condensing boilers.</StyledParagraph>
                <StyledParagraph>The three central atriums are served by local chilled and heating hot water fan coil units (FCUs), while the museum and gallery areas are served by chilled and heating hot water air handling units (AHUs) with humidity control where required.</StyledParagraph>
                <StyledParagraph>Adjacent to the laboratories are “write-up” areas served by active chilled beams served by 100 per cent outside air chilled water AHUs. Local heating is provided at the beams. Enthalpy wheels are used in the pre-treatment of outside air.</StyledParagraph>
                <StyledParagraph>The AHU plant serving the laboratory areas are 100 per cent outside air, chilled water AHUs with local reheat provided via duct-mounted heating hot water coils. The AHUs are also provided with run-around coils from the heat recovery system installed within the central manifolded exhaust system.</StyledParagraph>
                <StyledParagraph>The laboratory spaces’ supply and exhaust air systems were served by the unique laboratory airflow control system(LACS) consisting of <Link to="https://venturivalves.com.au/">VVA venturi valves</Link> both on the supply and exhaust system. These modulate up and down to suit thermal and/or exhaust requirements in order to maintain the volumetric offsets required to meet the PC2 requirements of the facility.</StyledParagraph>
                <StyledParagraph>As the incumbent building management system (BMS) service provider to UniSA, Air Con Serve were engaged during the design phase to provide engineering advice on the structure of the facility’s BMS.</StyledParagraph>
                <StyledParagraph>Ultimately, Air Con Serve chose Tridium’s Niagara 4 platform feeling this was the best control solution given the many unique and complex challenges they faced. These included integration to other equipment such as the Laboratory Airflow Control System (LACS), Aircuity – the indoor air quality monitoring system, load shedding control, manifolded exhaust system, and laboratory gas detection.</StyledParagraph>
                <StyledParagraph>Post occupancy, the Cancer Research Institute has surpassed the clients’ expectations, receiving glowing feedback from the building occupants and achieving a coveted LEED Gold certification. It is a testament to the project team who were engaged and proactive and were willing to work in a collaborative fashion. Least of all, the building was delivered on budget and continues to achieve the quality outcomes that enables the university to attract students and world-class research.</StyledParagraph>            
            </Reveals.FadeBottom>
        </Section>
        <Section backgroundColor={styles.palette.darkGray} margin="large">
            <Reveals.FadeBottom>
                <ImageContainer>
                    <StyledImage style={{padding: 50, background:"white"}} src="/img/other/niagara.webp" alt="powered by niagara" />
                </ImageContainer>
            </Reveals.FadeBottom>
        </Section>
    </Layout>
);

export default ProjectPage;